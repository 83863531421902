import viberlink_logo from "../assets/images/viberlink-black.png";
import { useState } from "react";
import { useCheckBillingHooks } from "../utils/CheckRegistrationHooks";
import { Link } from "react-router-dom";
import { convertToRupiah } from "../tools/numerictools";

function CheckBillingPage() {
  const [registration_number, setRegistrationNumber] = useState();
  const { data, getData, error, loading, success } = useCheckBillingHooks();

  const onSubmitCheck = (event) => {
    event.preventDefault();
    getData({ n: registration_number });
  };

  return (
    <div className="page-content check-registration">
      <img src={viberlink_logo} className="mb-3" alt="" srcset="" width="300" />
      <div className="form-v4-content" style={{ display: "block" }}>
        <form
          className="form-detail"
          onSubmit={(event) => onSubmitCheck(event)}
        >
          <h2 className="text-center">Cek Tagihan Pelanggan</h2>
          <div className="row">
            <div className="col col-md-8">
              <label htmlFor="registration_number">Nomor Registrasi</label>
              <input
                type="text"
                name="registration_number"
                id="registration_number"
                className="input-text"
                value={registration_number}
                placeholder="REGVBxxxxx"
                onChange={(event) => setRegistrationNumber(event.target.value)}
              />
            </div>
            <div className="col-auto">
              <div className="form-row-last pt-4">
                <button
                  type="submit"
                  name="register"
                  style={{ height: "47px" }}
                  className="btn register btn-primary btn-block"
                  disabled={loading}
                >
                  {loading ? "Mengecek" : "Cek Billing"}
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="detail-registration mb-4">
          {success ? (
            <div className="receipt-container">
              <div className="text-container">
                <span className="receipt-number mb-2">
                  {data.billing_number}
                </span>
                <span className="customer-name">{data.name}</span>
                <span className="package-name">{data.internet_package}</span>
                <span className="amount">
                  Rp {convertToRupiah(data.final_amount || 0)}
                </span>
              </div>
              <Link
                to={`/billing/${data.billing_number}/auth/${data.payment_token}`}
                className="btn btn-primary"
              >
                Bayar Sekarang
              </Link>
            </div>
          ) : null}
          {error ? (
            <div className="alert alert-danger">
              <span>{error}</span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default CheckBillingPage;
